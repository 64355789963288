export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Carolyn Perkins',
        email: 'carolyn.p@elstar.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin',
    },
]


export const loginData = {
    "status": "success",
    "client_status": "active",
    "organization": "Giving Hands Ministry",
    "website": "https://helpyousponsor.com",
    "token": "token",
    "org_created_at": "2017-02-22T17:40:21.000000Z",
    "last_payment_date": "2023-10-19T18:30:27.000000Z",
    "last_payment_amount": 20,
    "monthly_payment": 211,
    "user": {
      "id": 542,
      "email": "dev@helpyousponsor.com",
      "permissions": null,
      "activated": 1,
      "activation_code": null,
      "activated_at": "2017-02-23 01:41:10",
      "last_login": "2024-07-18 11:55:06",
      "reset_password_code": null,
      "first_name": "Leslie",
      "last_name": "Jobs",
      "created_at": "2017-02-23T01:40:21.000000Z",
      "updated_at": "2024-07-18T11:55:06.000000Z",
      "client_id": "207",
      "group_id": 301,
      "email_verified_at": null,
      "__hevo__database_name": "apphys_new",
      "__hevo__ingested_at": 1678920545435,
      "__hevo__marked_deleted": 0,
      "__hevo__source_modified_at": 1678920204000,
      "onboarding_status": null,
      "phone": null,
      "client": {
        "id": 207,
        "organization": "Giving Hands Ministry",
        "website": "https://helpyousponsor.com",
        "ein": null,
        "stripe_cust_id": "stripe_cust_id",
        "arb_enabled": "",
        "stripe_card_id": "stripe_card_id",
        "email": "dev@helpyousponsor.com",
        "logo": "https://i.ytimg.com/vi/uZ_fkDj-bqQ/0.jpg",
        "status": "active",
        "plan": "max",
        "logout": "false",
        "box_client_id": null,
        "box_client_secret": null,
        "box_access_token": "",
        "box_refresh_token": "",
        "created_at": "2017-02-22T17:40:21.000000Z",
        "updated_at": "2024-04-08T18:55:12.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1679725251508,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": null,
        "new_user": 0,
        "stripe_connect_id": null,
        "completed_stripe_onboarding": 0,
        "subaccount_id": null
      },
      "group": {
        "id": 301,
        "name": "HYS Demo",
        "permissions": "{\"id\":301,\"deleted_at\":null,\"created_at\":\"2017-02-22T20:40:21.000000Z\",\"updated_at\":\"2023-05-03T09:41:45.000000Z\",\"client_id\":\"207\",\"__hevo__ingested_at\":1679725257548,\"__hevo__marked_deleted\":0,\"__hevo__source_modified_at\":null,\"__hevo__database_name\":\"apphys_new\",\"group_all\":\"1\",\"account\":\"1\",\"groups\":\"1\",\"group-301\":\"1\",\"admins\":\"1\",\"forms\":\"1\",\"new_form\":\"1\",\"manage_programs\":\"1\",\"manage_settings\":\"1\",\"manage_designations\":\"1\",\"manage_email\":\"1\",\"email_manager\":\"1\",\"donations\":\"1\",\"form_report\":\"1\",\"program-1221\":\"1\",\"program-929\":\"1\",\"donor-639\":\"1\",\"program-all\":\"1\",\"donor-all\":\"1\",\"program-3437\":\"1\",\"program-3438\":\"1\",\"program-3439\":\"1\",\"program-3440\":\"1\",\"program-3441\":\"1\",\"program-3442\":\"1\",\"program-3443\":\"1\",\"program-3494\":\"1\",\"program-3495\":\"1\",\"program-3506\":\"1\",\"program-3507\":\"1\",\"disable_entity_archive\":null,\"disable_donor_archive\":null,\"disable_donor_delete\":null,\"disable_donor_restore\":null,\"disable_entity_delete\":null,\"disable_entity_restore\":null,\"group_id\":301,\"program-3510\":\"1\",\"program-3515\":\"1\",\"program-3516\":\"1\",\"program-3517\":\"1\",\"program-3518\":\"1\",\"disable_message_send_without_approval\":null,\"send_status\":\"1\",\"program-3521\":\"1\"}",
        "deleted_at": null,
        "created_at": "2017-02-22T17:40:21.000000Z",
        "updated_at": "2024-06-13T08:35:10.000000Z",
        "client_id": "207",
        "__hevo__ingested_at": 1679725257548,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": null,
        "__hevo__database_name": "apphys_new"
      }
    },
    "plan": "max",
    "permissions": {
      "id": 301,
      "deleted_at": null,
      "created_at": "2017-02-22T20:40:21.000000Z",
      "updated_at": "2023-05-03T09:41:45.000000Z",
      "client_id": "207",
      "__hevo__ingested_at": 1679725257548,
      "__hevo__marked_deleted": 0,
      "__hevo__source_modified_at": null,
      "__hevo__database_name": "apphys_new",
      "group_all": "1",
      "account": "1",
      "groups": "1",
      "group-301": "1",
      "admins": "1",
      "forms": "1",
      "new_form": "1",
      "manage_programs": "1",
      "manage_settings": "1",
      "manage_designations": "1",
      "manage_email": "1",
      "email_manager": "1",
      "donations": "1",
      "form_report": "1",
      "program-1221": "1",
      "program-929": "1",
      "donor-639": "1",
      "program-all": "1",
      "donor-all": "1",
      "program-3437": "1",
      "program-3438": "1",
      "program-3439": "1",
      "program-3440": "1",
      "program-3441": "1",
      "program-3442": "1",
      "program-3443": "1",
      "program-3494": "1",
      "program-3495": "1",
      "program-3506": "1",
      "program-3507": "1",
      "disable_entity_archive": null,
      "disable_donor_archive": null,
      "disable_donor_delete": null,
      "disable_donor_restore": null,
      "disable_entity_delete": null,
      "disable_entity_restore": null,
      "group_id": 301,
      "program-3510": "1",
      "program-3515": "1",
      "program-3516": "1",
      "program-3517": "1",
      "program-3518": "1",
      "disable_message_send_without_approval": null,
      "send_status": "1",
      "program-3521": "1"
    }
}
